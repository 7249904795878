﻿var _printerName = null;

function findPrinter() {

    var printers = dymo.label.framework.getPrinters();

    if (printers.length === 0)
        throw "DYMO printer tapılmadı.";

    _printerName = printers[0].name;
}

//let labelInfo = new LabelInfo("Volvo", "Yeni yanacaq filteri", 1234434522, '01324345533', 133.45)
function printLabel(labelInfo, count) {

    if (_printerName === null) {

        findPrinter();

        setTimeout(printLabel(labelInfo, count), 3000);
    }
    else {

        const labelXml = getLabelXml(labelInfo);

        var label = dymo.label.framework.openLabelXml(labelXml);

        if (labelInfo.barcode.length > 12) {
            labelInfo.barcode = labelInfo.barcode.substring(0, 12);
        }

        label.setObjectText("BARCODE", labelInfo.barcode);

        for (var i = 0; i < count; i++) {
            label.print(_printerName);
        }
    }


}

function dymoFrameworkInit() {
    dymo.label.framework.trace = 1;
    dymo.label.framework.init(findPrinter);
}

function getLabelXml(labelInfo) {

	var labelXml = "<?xml version=\"1.0\" encoding=\"utf-8\"?>" +
		"<DieCutLabel Version=\"8.0\" Units=\"twips\" MediaType=\"Durable\">" +
		"	<PaperOrientation>Landscape</PaperOrientation>" +
		"	<Id>LW_DURABLE_25X89mm</Id>" +
		"	<IsOutlined>false</IsOutlined>" +
		"	<PaperName>1933081 Drbl 1 x 3-1/2 in</PaperName>" +
		"	<DrawCommands>" +
		"		<RoundRectangle X=\"0\" Y=\"0\" Width=\"1440\" Height=\"5040\" Rx=\"90.708661417\" Ry=\"90.708661417\" />" +
		"	</DrawCommands>" +
		"	<ObjectInfo>" +
		"		<TextObject>" +
		"			<Name>METİN</Name>" +
		"			<ForeColor Alpha=\"255\" Red=\"0\" Green=\"0\" Blue=\"0\" />" +
		"			<BackColor Alpha=\"0\" Red=\"255\" Green=\"255\" Blue=\"255\" />" +
		"			<LinkedObjectName />" +
		"			<Rotation>Rotation0</Rotation>" +
		"			<IsMirrored>False</IsMirrored>" +
		"			<IsVariable>False</IsVariable>" +
		"			<GroupID>-1</GroupID>" +
		"			<IsOutlined>False</IsOutlined>" +
		"			<HorizontalAlignment>Center</HorizontalAlignment>" +
		"			<VerticalAlignment>Bottom</VerticalAlignment>" +
		"			<TextFitMode>AlwaysFit</TextFitMode>" +
		"			<UseFullFontHeight>True</UseFullFontHeight>" +
		"			<Verticalized>False</Verticalized>" +
		"			<StyledText>" +
		"				<Element>" +
		"					<String xml:space=\"preserve\">" + labelInfo.vehicleMake + " " + labelInfo.vehicleModel + " </String>" +
		"					<Attributes>" +
		"						<Font Family=\"Arial\" Size=\"72\" Bold=\"False\" Italic=\"False\" Underline=\"False\" Strikeout=\"False\" />" +
		"						<ForeColor Alpha=\"255\" Red=\"0\" Green=\"0\" Blue=\"0\" HueScale=\"100\" />" +
		"					</Attributes>" +
		"				</Element>" +
		"			</StyledText>" +
		"		</TextObject>" +
		"		<Bounds X=\"330\" Y=\"131.999999999999\" Width=\"3060\" Height=\"444\" />" +
		"	</ObjectInfo>" +
		"	<ObjectInfo>" +
		"		<BarcodeObject>" +
		"			<Name>BARCODE</Name>" +
		"			<ForeColor Alpha=\"255\" Red=\"0\" Green=\"0\" Blue=\"0\" />" +
		"			<BackColor Alpha=\"0\" Red=\"255\" Green=\"255\" Blue=\"255\" />" +
		"			<LinkedObjectName />" +
		"			<Rotation>Rotation0</Rotation>" +
		"			<IsMirrored>False</IsMirrored>" +
		"			<IsVariable>True</IsVariable>" +
		"			<GroupID>-1</GroupID>" +
		"			<IsOutlined>False</IsOutlined>" +
		"			<Text>7170105657</Text>" +
		"			<Type>Ean13</Type>" +
		"			<Size>Small</Size>" +
		"			<TextPosition>Bottom</TextPosition>" +
		"			<TextFont Family=\"Arial\" Size=\"8\" Bold=\"False\" Italic=\"False\" Underline=\"False\" Strikeout=\"False\" />" +
		"			<CheckSumFont Family=\"Arial\" Size=\"8\" Bold=\"False\" Italic=\"False\" Underline=\"False\" Strikeout=\"False\" />" +
		"			<TextEmbedding>Full</TextEmbedding>" +
		"			<ECLevel>0</ECLevel>" +
		"			<HorizontalAlignment>Center</HorizontalAlignment>" +
		"			<QuietZonesPadding Left=\"0\" Top=\"0\" Right=\"0\" Bottom=\"0\" />" +
		"		</BarcodeObject>" +
		"		<Bounds X=\"3285\" Y=\"288\" Width=\"1665\" Height=\"864\" />" +
		"	</ObjectInfo>" +
		"	<ObjectInfo>" +
		"		<TextObject>" +
		"			<Name>TEXT_1</Name>" +
		"			<ForeColor Alpha=\"255\" Red=\"0\" Green=\"0\" Blue=\"0\" />" +
		"			<BackColor Alpha=\"0\" Red=\"255\" Green=\"255\" Blue=\"255\" />" +
		"			<LinkedObjectName />" +
		"			<Rotation>Rotation0</Rotation>" +
		"			<IsMirrored>False</IsMirrored>" +
		"			<IsVariable>False</IsVariable>" +
		"			<GroupID>-1</GroupID>" +
		"			<IsOutlined>False</IsOutlined>" +
		"			<HorizontalAlignment>Center</HorizontalAlignment>" +
		"			<VerticalAlignment>Top</VerticalAlignment>" +
		"			<TextFitMode>AlwaysFit</TextFitMode>" +
		"			<UseFullFontHeight>True</UseFullFontHeight>" +
		"			<Verticalized>False</Verticalized>" +
		"			<StyledText>" +
		"				<Element>" +
		"					<String xml:space=\"preserve\">" + labelInfo.productPartNumber + "</String>" +
		"					<Attributes>" +
		"						<Font Family=\"Arial\" Size=\"26\" Bold=\"False\" Italic=\"False\" Underline=\"False\" Strikeout=\"False\" />" +
		"						<ForeColor Alpha=\"255\" Red=\"0\" Green=\"0\" Blue=\"0\" HueScale=\"100\" />" +
		"					</Attributes>" +
		"				</Element>" +
		"			</StyledText>" +
		"		</TextObject>" +
		"		<Bounds X=\"330\" Y=\"1020\" Width=\"2736\" Height=\"336\" />" +
		"	</ObjectInfo>" +
		"	<ObjectInfo>" +
		"		<TextObject>" +
		"			<Name>TEXT__1</Name>" +
		"			<ForeColor Alpha=\"255\" Red=\"0\" Green=\"0\" Blue=\"0\" />" +
		"			<BackColor Alpha=\"0\" Red=\"255\" Green=\"255\" Blue=\"255\" />" +
		"			<LinkedObjectName />" +
		"			<Rotation>Rotation0</Rotation>" +
		"			<IsMirrored>False</IsMirrored>" +
		"			<IsVariable>False</IsVariable>" +
		"			<GroupID>-1</GroupID>" +
		"			<IsOutlined>False</IsOutlined>" +
		"			<HorizontalAlignment>Center</HorizontalAlignment>" +
		"			<VerticalAlignment>Top</VerticalAlignment>" +
		"			<TextFitMode>AlwaysFit</TextFitMode>" +
		"			<UseFullFontHeight>True</UseFullFontHeight>" +
		"			<Verticalized>False</Verticalized>" +
		"			<StyledText>" +
		"				<Element>" +
		"					<String xml:space=\"preserve\"> " + labelInfo.productBrandName + " " + labelInfo.productName + " </String>" +
		"					<Attributes>" +
		"						<Font Family=\"Arial\" Size=\"26\" Bold=\"False\" Italic=\"False\" Underline=\"False\" Strikeout=\"False\" />" +
		"						<ForeColor Alpha=\"255\" Red=\"0\" Green=\"0\" Blue=\"0\" HueScale=\"100\" />" +
		"					</Attributes>" +
		"				</Element>" +
		"			</StyledText>" +
		"		</TextObject>" +
		"		<Bounds X=\"330\" Y=\"624\" Width=\"2868\" Height=\"336\" />" +
		"	</ObjectInfo>" +
		"</DieCutLabel>";

    return labelXml;
}

class LabelInfo {
    constructor(productBrandName, productName, productPartNumber, barcode, vehicleMake, vehicleModel) {
        this.productBrandName = productBrandName;
        this.productName = productName;
        this.productPartNumber = productPartNumber;
        this.barcode = barcode;
        this.vehicleMake = vehicleMake;
        this.vehicleModel = vehicleModel;
    }
}